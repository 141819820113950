import LoadingState from "../../utils/loading-state";
import actionTypes from "../actionTypes";

const INITIAL_STATE = {
  requests: {}
};

export const splitActionType = (actionType) => {
  const matches = actionType.match(/^(.*)_([^_]+)$/);

  if (matches) {
    return {
      category: matches[1],
      type: matches[2]
    };
  }

  return {};
};

const categoryPairs = [
  [actionTypes.DELETE_INTEGRATION_REQUEST, actionTypes.GET_INTEGRATION_REQUEST],
  [
    actionTypes.SET_USER_SETTINGS_REQUEST,
    actionTypes.GET_USER_SETTINGS_REQUEST
  ],
  [
    actionTypes.UPDATE_NOTES_TEMPLATE_REQUEST,
    actionTypes.GET_NOTES_TEMPLATE_REQUEST
  ],
  [actionTypes.UPDATE_NOTE_REQUEST, actionTypes.GET_NOTE_REQUEST],
  [
    actionTypes.UPDATE_ADMIN_SETTINGS_REQUEST,
    actionTypes.GET_ADMIN_SETTINGS_REQUEST
  ]
].map((pair) => {
  return pair.map((item) => {
    const { category } = splitActionType(item);
    return category;
  });
});

const getLinkedCategory = (category) => {
  const pair = categoryPairs.find((pair) => pair[0] === category);

  return pair ? pair[1] : null;
};

const successCallbacks = {};

const registerSuccessCallback = (action, callback) => {
  const { category } = splitActionType(action);

  successCallbacks[category] = callback;
};

const getSuccessCallback = (category) => {
  return successCallbacks[category] || ((state) => state);
};

const requests = (state = INITIAL_STATE, action) => {
  const { category, type } = splitActionType(action.type);

  if (category) {
    const newState = { requests: { ...state.requests } };

    if (!newState.requests[category]) {
      newState.requests[category] = {
        loadingState: LoadingState.UNINITIALIZED,
        data: null,
        error: null,
        parameters: null
      };
    }

    const linkedCategory = getLinkedCategory(category);

    if (action.parameters) {
      newState.requests[category].parameters = action.parameters;
    }

    switch (type) {
      case "REQUEST":
        newState.requests[category].loadingState = LoadingState.IN_PROGRESS;
        if (action.payload) {
          newState.requests[category].data = action.payload;
        }

        newState.requests[category].error = null;
        return newState;
      case "SUCCESS":
        newState.requests[category].loadingState = LoadingState.COMPLETED;
        newState.requests[category].data = action.payload;

        if (linkedCategory && newState.requests[linkedCategory]) {
          newState.requests[linkedCategory].data = action.payload;
        }

        return getSuccessCallback(category)(newState, action.payload);
      case "FAILURE":
        newState.requests[category].loadingState = LoadingState.FAILED;
        newState.requests[category].error = action.error;
        return newState;

      case "RESET":
        newState.requests[category] = {};
        return newState;
    }
  }

  return state;
};

export const selectRequestState = (requestsData, actionType) => {
  const { category } = splitActionType(actionType);

  return requestsData.requests[category]
    ? requestsData.requests[category].loadingState
    : LoadingState.UNINITIALIZED;
};

export const selectRequestData = (requestsData, actionType) => {
  const { category } = splitActionType(actionType);

  return requestsData.requests[category]
    ? requestsData.requests[category].data
    : null;
};

export const selectRequestParameters = (requestsData, actionType) => {
  const { category } = splitActionType(actionType);

  return requestsData.requests[category]
    ? requestsData.requests[category].parameters
    : null;
};

export const selectRequestError = (requestsData, actionType) => {
  const { category } = splitActionType(actionType);

  return requestsData.requests[category]
    ? requestsData.requests[category].error
    : null;
};

export default requests;
