import httpService from "./http.service";

const getConfig = () =>
  httpService
    .get(`/config`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getLatestCRMAccountsLog = () =>
  httpService
    .get(`/config/crm`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getSalesforceInstallation = () =>
  httpService
    .get(`/config/salesforce/installation`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getSSOInstallation = () =>
  httpService
    .get(`/config/sso`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getAdminSettings = () =>
  httpService
    .get(`/config/admin`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateAdminSettings = (changes) =>
  httpService
    .patch(`/config/admin`, changes)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getConfig,
  getLatestCRMAccountsLog,
  getAdminSettings,
  updateAdminSettings,
  getSalesforceInstallation,
  getSSOInstallation
};
export default methods;
