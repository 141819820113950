import React, { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Heading, Text, Alert, Icon } from "../../../../components";
import styled from "styled-components";
import { useString as s } from "../../../../components/StringProvider";
import pusherService from "../../../../services/pusher.service";
import ChannelNames from "../../../../utils/channel-names";
import httpService from "../../../../services/http.service";
import { selectRequestData } from "../../../../store/reducers";
import { getSSOInstallation } from "../../../../store/actions/config";
import types from "../../../../store/actionTypes";
import JobStatuses from "../../../../utils/job-statuses";
import PusherEventNames from "../../../../utils/constants/pusher-event-names";
import SalesforceInstallationStatus from "../SalesforceInstallation/SalesforceInstallationStatus";

const SalesforceSSOInstallation = ({ ssoInstallation, getSSOInstallation }) => {
  const header = s(
    "salesforce.ssoInstallation.header",
    "Salesforce SSO Installation"
  );
  const description = s(
    "salesforce.ssoInstallation.description",
    "Monitor the installation status of single sign-on."
  );
  const installationFailed = s(
    "salesforce.ssoInstallation.failed",
    "Something went wrong during the installation. Please verify your Salesforce configuration and try again."
  );
  const { status } = ssoInstallation || {};

  useEffect(() => {
    getSSOInstallation();
  }, []);

  const tenantId = httpService.getTenantId();

  const onUpdateSSOInstallation = () => {
    getSSOInstallation();
  };

  useEffect(() => {
    pusherService.subscribe({
      channelName: ChannelNames.SSO_INSTALLATION({ tenantId }),
      eventName: PusherEventNames.UPDATE,
      callback: onUpdateSSOInstallation
    });

    return () => {
      pusherService.unsubscribe({
        channelName: ChannelNames.SSO_INSTALLATION({ tenantId }),
        eventName: PusherEventNames.UPDATE,
        callback: onUpdateSSOInstallation
      });
    };
  }, []);

  return (
    <Container>
      <Heading level={"h4"}>{header}</Heading>
      <SalesforceInstallationStatus status={status} />
      <ContainerInner>
        <Text>{description}</Text>
        {JobStatuses.FAILED === status && (
          <Alert
            data-cy={"salesforce-installation-failed"}
            message={installationFailed}
            type={"error"}
            icon={<Icon size={"larger"} name={"alert"} />}
            showIcon
          />
        )}
      </ContainerInner>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const mapStateToProps = (state) => ({
  ssoInstallation: selectRequestData(state, types.GET_SSO_INSTALLATION_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSSOInstallation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SalesforceSSOInstallation
);
