import PropType from "prop-types";
import Badge from "./Badge";
import { themeProp } from "../../theme";
import CategoryBadge from "./CategoryBadge";
import React from "react";
import { useString as s } from "../StringProvider";
import { useMobileMediaQuery } from "../Responsive";
import UNCATEGORIZED_CATEGORY_CODE from "../../utils/uncategorized-category-code";

const CategoryBadges = ({ showKeyObjectiveBadge, definition, disabled }) => {
  const keyObjectiveText = s(
    "discovery.kpi.card.keyObjective",
    "Critical Business Issue"
  );
  const oneTimeBenefit = s(
    "discovery.kpi.card.oneTimeBenefit",
    "One-time benefit"
  );
  const isMobile = useMobileMediaQuery();

  return (
    <span>
      {showKeyObjectiveBadge ? (
        <>
          <Badge
            bg={
              disabled
                ? themeProp("palette.gray2")
                : themeProp("palette.secondary")
            }
            color={"surface"}
            className={"category-badge"}
            variant={isMobile ? "micro" : undefined}
            data-cy="challenge-card-cbi-tag"
          >
            {keyObjectiveText}
          </Badge>
          &nbsp;
        </>
      ) : null}
      {definition.categoryCode !== UNCATEGORIZED_CATEGORY_CODE && (
        <CategoryBadge
          categoryCode={definition.categoryCode}
          disabled={disabled}
          variant={isMobile ? "micro" : undefined}
        />
      )}
      {definition.oneTimeBenefit ? (
        <>
          &nbsp;
          <Badge
            bg={
              disabled
                ? themeProp("palette.gray2")
                : themeProp("palette.success")
            }
            color={"surface"}
            className={"category-badge"}
            variant={isMobile ? "micro" : undefined}
          >
            {oneTimeBenefit}
          </Badge>
        </>
      ) : null}
    </span>
  );
};

CategoryBadges.propTypes = {
  showKeyObjectiveBadge: PropType.bool,
  definition: PropType.object.isRequired,
  disabled: PropType.bool
};

CategoryBadges.defaultProps = {
  variant: "small"
};

export default CategoryBadges;
