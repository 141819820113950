import React, { useContext } from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import { SettingsContext } from "../../../components/SettingsProvider";
import Heading from "../../../components/Heading";
import Card from "../../../components/Card";
import { formatCurrencyString } from "../../../utils/formatting";
import { useMobileMediaQuery } from "../../../components/Responsive";
import {
  selectDiscoveryCashflow,
  selectDiscoveryCurrency,
  selectDiscoveryROI
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";

const ROIChart = ({ cashflow, roi, currency }) => {
  const isMobile = useMobileMediaQuery();
  const { settings } = useContext(SettingsContext);
  const localSettings = { ...settings, currency };
  const { evaluationPeriod } = roi;

  const labels = [];
  for (let i = 0; i < evaluationPeriod; i++) {
    labels.push(`Year ${i + 1}`);
  }
  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: s("discovery.roi.summary.chart.dataset1.label", "Net Benefit"),
        borderColor: "#54C9D4",
        borderWidth: 2,
        fill: false,
        data: cashflow.netBenefitPerYear,
        lineTension: 0,
        pointRadius: 5,
        pointBackgroundColor: "#54C9D4"
      },
      {
        type: "line",
        lineTension: 0,
        label: s(
          "discovery.roi.summary.chart.dataset2.label",
          "Cumulative Net Benefit"
        ),
        borderColor: "#7CB305",
        borderWidth: 2,
        fill: false,
        data: cashflow.cumulativeNetBenefitPerYear,
        pointRadius: 5,
        pointBackgroundColor: "#7CB305"
      },
      {
        type: "bar",
        label: s("discovery.roi.summary.chart.dataset3.label", "Investment"),
        data: cashflow.investmentPerYear.map((num) => -Math.abs(num)),
        backgroundColor: "#D55F55"
      },
      {
        type: "bar",
        label: s("discovery.roi.summary.chart.dataset4.label", "Benefits"),
        data: cashflow.benefitPerYear,
        backgroundColor: "#5E54D4"
      }
    ]
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            color: "#FF5733",
            beginAtZero: true,
            callback: function (value) {
              return formatCurrencyString({
                value,
                settings: localSettings,
                options: {
                  notation: "compact"
                }
              });
            }
          }
        }
      ],
      xAxes: [
        {
          color: "#FF5733",
          drawBorder: false,
          stacked: true,
          callback: function (value) {
            return value + 1;
          }
        }
      ]
    },
    legend: {
      position: "top",
      labels: {
        usePointStyle: false,
        boxWidth: 22
      }
    },
    tooltips: {
      enabled: true,
      backgroundColor: "black",
      titleColor: "white",
      bodyColor: "white",
      borderColor: "transparent",
      borderWidth: 0,
      displayColors: true,
      callbacks: {
        label: function (tooltipItem, data) {
          const value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

          return formatCurrencyString({
            value,
            settings: { ...localSettings, trim: true }
          });
        }
      }
    }
  };

  return (
    <ROIChartContainer className={isMobile ? "mobile" : undefined}>
      <CardHeader>
        <Heading level="h5" bold>
          {s("discovery.roi.summary.chart.heading", "Breakdown by Years")}
        </Heading>
      </CardHeader>
      <CardBody>
        <Bar data={data} options={options} height={450} />
      </CardBody>
    </ROIChartContainer>
  );
};

const ROIChartContainer = styled(Card)`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &.mobile {
    margin-top: 0px;
  }
`;

const CardHeader = styled.div``;

const CardBody = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => ({
  cashflow: selectDiscoveryCashflow(state),
  roi: selectDiscoveryROI(state),
  currency: selectDiscoveryCurrency(state)
});

export default compose(connect(mapStateToProps))(ROIChart);
