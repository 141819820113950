import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { FormItemLabel } from "../../Form";
import NumberInput from "../../Input/NumberInput";
import Text from "../../Text";
import PropType from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  hasPermission,
  selectDiscoveryMetric,
  selectRequestData
} from "../../../store/reducers";
import {
  getMetricFormatter,
  getMetricParser,
  getMetricTooltipFormatter
} from "../../../utils/formatting";
import { saveDiscoveryMetric } from "../../../store/actions/discoveries";
import { SettingsContext } from "../../SettingsProvider";
import { themeProp } from "../../../theme";
import Permissions from "../../../utils/permissions";
import ResetButton from "../../../Pages/BusinessMetrics/components/ResetButton";
import { useString as s } from "../../StringProvider";
import actionTypes from "../../../store/actionTypes";

const MetricInput = ({
  currency,
  metric,
  metricCode,
  onMetricChange,
  canSeeAllMetrics,
  onCommit
}) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;

  const [metricValue, setMetricValue] = useState(metric.value);
  const [isUpdating, setUpdating] = useState(false);
  const [modified, setModified] = useState(metric.modified);

  useEffect(() => {
    setModified(metricValue !== metric.defaultValue);
  }, [metricValue]);

  const onResetMetric = () => {
    setModified(false);
    setMetricValue(metric.defaultValue);
    onChange(metric.defaultValue);
  };

  const tooltipFormatter = getMetricTooltipFormatter({
    type: metric.type,
    locale,
    currency
  });

  const tooltipText = s(
    "discovery.metrics.page.reset.tooltip",
    "Reset to default {defaultValue}",
    { defaultValue: tooltipFormatter(metric.defaultValue) }
  );

  const resetButton = (
    <ResetButton
      visible={modified && !isUpdating}
      onReset={onResetMetric}
      tooltipText={tooltipText}
    />
  );

  useEffect(() => {
    if (!isUpdating || String(metric.value) === String(metricValue)) {
      setMetricValue(metric.value);
    }
  }, [metric.value]);

  useEffect(() => {
    setUpdating(false);
  }, [metric]);

  const isNumber = (value) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
  };

  const formatter = getMetricFormatter({
    type: metric.type,
    locale,
    currency,
    options: { notation: "standard" }
  });

  const parser = getMetricParser({ type: metric.type, locale, currency });

  const onChange = (v) => {
    if (isNumber(v)) {
      if (metric.type === "Percentage" && v > 100) {
        setMetricValue(100);
        onMetricChange({ metricCode, value: 100 });
      } else {
        setMetricValue(v);
        onMetricChange({ metricCode, value: v });
      }
    }
  };

  if (!canSeeAllMetrics && !metric.visible) {
    return null;
  }

  return (
    <InputContainer>
      <Text>
        <FormItemLabel label={metric.name} tooltip={metric.description} />
      </Text>
      <InputAndResetContainer>
        <NumberInput
          size={"medium"}
          value={Number(metricValue)}
          formatter={formatter}
          parser={parser}
          onChange={onChange}
          onCommit={onCommit}
          data-cy={`metric-input-${metricCode}`}
        />
        {resetButton}
      </InputAndResetContainer>
    </InputContainer>
  );
};

const InputAndResetContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    height: fit-content;
    color: ${themeProp("palette.secondary")};
    font-weight: ${themeProp("typography.body.fontWeight")} !important;
  }

  .ant-input-number {
    width: 100%;
    height: fit-content !important;
  }
`;

MetricInput.propTypes = {
  metricCode: PropType.string.isRequired,
  currency: PropType.string.isRequired,
  discoveryId: PropType.string.isRequired
};

const mapStateToProps = (state, props) => ({
  canSeeAllMetrics: hasPermission(state, Permissions.SEE_ALL_METRICS),
  metric:
    selectRequestData(
      state,
      actionTypes.DISCOVERY_PREVIEW_KPI_REQUEST
    )?.discovery?.metrics?.find((m) => m.metricCode === props.metricCode) ||
    selectDiscoveryMetric(state, props.metricCode)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryMetric
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MetricInput
);
