import { selectedKPIsFilter } from "./filters/kpi-filters";
import { sortDiscoveryKPIsByOrder } from "./sorting";

const getKPIsImpactedByMetrics = ({ discoveryKPI, discoveryKPIs }) => {
  const metricMappings = discoveryKPI?.definition?.metricMappings;
  const selectedKPIs = selectedKPIsFilter(discoveryKPIs).filter(
    (kpi) => kpi.kpiCode !== discoveryKPI.kpiCode
  );

  const kpis = selectedKPIs.filter((kpi) =>
    kpi?.definition?.metricMappings?.some((metric) =>
      metricMappings?.includes(metric)
    )
  );

  kpis.sort(sortDiscoveryKPIsByOrder);

  return kpis;
};

export default getKPIsImpactedByMetrics;
