import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { useString as s } from "../../StringProvider";
import Form from "../../Form";
import DiscoveryAlert from "./DiscoveryAlert";
import PropTypes from "prop-types";
import {
  selectAddDiscoveryError,
  selectAddDiscoveryLoadingState,
  selectAddDiscoveryResult,
  selectMasterDataVersion,
  selectRequestData
} from "../../../store/reducers";
import { addDiscovery } from "../../../store/actions/discoveries";
import { setUserSettings } from "../../../store/actions/user-settings";
import { message } from "antd";
import actionTypes from "../../../store/actionTypes";
import NewButton from "../../NewButton";
import { getMasterData } from "../../../store/actions/master-data";
import { getLatestVersion } from "../../../store/actions/versions";
import { selectLatestVersionNumber } from "../../../store/selectors/versions";
import useLoadingState from "../../../utils/use-loading-state";
import DiscoveryForm from "../../Discovery/DiscoveryForm";
import Modal from "../../Modal";
import LoadingState from "../../../utils/loading-state";

const NewDiscoveryModal = ({
  onClose,
  history,
  userSettings,
  newDiscovery,
  error,
  addDiscovery,
  setUserSettings,
  getLatestVersion,
  latestVersionNumber,
  getMasterData,
  masterDataVersion,
  addDiscoveryLoadingState
}) => {
  const [form] = Form.useForm();
  const okText = s("new_discovery.popup.button1", "Start discovery");
  const cancelText = s("new_discovery.popup.button2", "Don't create");
  const errorText = s(
    "new_discovery.popup.error.text",
    "Failed to create discovery"
  );
  const title = s("new_discovery.popup.header", "New Discovery");
  const [isDuplicateError, setDuplicateError] = useState(false);

  useEffect(() => {
    getLatestVersion();
  }, []);

  useEffect(() => {
    if (latestVersionNumber && latestVersionNumber !== masterDataVersion) {
      getMasterData({ version: latestVersionNumber });
    }
  }, [latestVersionNumber, masterDataVersion]);

  useEffect(() => {
    if (userSettings && userSettings.discoveryTypeCode) {
      form.setFieldsValue({
        discoveryTypeCode: userSettings.discoveryTypeCode
      });
    }
  }, [userSettings]);

  useLoadingState(
    addDiscoveryLoadingState,
    () => {
      setUserSettings({
        discoveryTypeCode: newDiscovery.discoveryTypeCode
      });
      onClose();
      history.push({
        pathname: `/discoveries/${newDiscovery["_id"]}/start-discovery`
      });
    },
    () => {
      if (error) {
        if (error.status === 409) {
          setDuplicateError(true);
        } else {
          message.error(errorText);
        }
      } else {
        setDuplicateError(false);
      }
    }
  );

  const onFinish = async (values) => {
    addDiscovery({ ...values });
  };

  return latestVersionNumber && latestVersionNumber === masterDataVersion ? (
    <Modal
      title={title}
      open={true}
      onCancel={onClose}
      data-cy={"new-discovery-modal"}
      className={"up-new-discovery-modal"}
    >
      {isDuplicateError ? <DiscoveryAlert /> : null}
      <DiscoveryForm
        form={form}
        okText={okText}
        onFinish={onFinish}
        isDiscoveryTypeEditable={true}
        loading={addDiscoveryLoadingState === LoadingState.IN_PROGRESS}
      >
        <NewButton type={"secondary"} onClick={onClose}>
          {cancelText}
        </NewButton>
      </DiscoveryForm>
    </Modal>
  ) : null;
};

NewDiscoveryModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  userSettings: selectRequestData(state, actionTypes.GET_USER_SETTINGS_REQUEST),
  newDiscovery: selectAddDiscoveryResult(state),
  error: selectAddDiscoveryError(state),
  masterDataVersion: selectMasterDataVersion(state),
  latestVersionNumber: selectLatestVersionNumber(state),
  addDiscoveryLoadingState: selectAddDiscoveryLoadingState(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDiscovery,
      setUserSettings,
      getMasterData,
      getLatestVersion
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NewDiscoveryModal);
