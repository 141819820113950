const ChannelNames = {
  NOTIFICATIONS: ({ userId, tenantId }) =>
    `private-notifications-${userId}@${tenantId}`,
  AUTH: ({ userId, tenantId }) => `private-auth-${userId}@${tenantId}`,
  JOBS: ({ jobId, tenantId }) => `private-jobs-${jobId}@${tenantId}`,
  DISCOVERY_NOTES: ({ discoveryId, tenantId }) =>
    `private-discovery-notes-${discoveryId}@${tenantId}`,
  CURRENT_CONFIGURATION: ({ tenantId }) =>
    `private-current-configuration@${tenantId}`,
  ENGAGEMENT: ({ tenantId }) => `private-engagement@${tenantId}`,
  SALESFORCE_INSTALLATION: ({ tenantId }) =>
    `private-salesforce-installation@${tenantId}`,
  SSO_INSTALLATION: ({ tenantId }) => `private-sso-installation@${tenantId}`
};

module.exports = ChannelNames;
